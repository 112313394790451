<template>
    <div>
      <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
          <v-layout align-center pr-4>
          <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
          <v-layout column>
              <div>
              <strong>{{ snackbar.title }}</strong>
              </div>
              <div>{{ snackbar.text }}</div>
          </v-layout>
          </v-layout>
          <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
          <v-icon>clear</v-icon>
          </v-btn>
      </v-snackbar>
      <v-container fluid class="mt-3">
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <v-card class="rounded-l green lighten-4" outlined height="198px">
              <v-card-title class="">
                <div class="d-flex">
                  <v-icon color="green">
                    mdi-bucket
                  </v-icon>
                  <div class="ml-3">
                    Active
                  </div>
                </div>
              </v-card-title>
              <v-card-text  class="p-3">
                <v-container class="py-auto fill-height">
                  <v-row align="center" justify="center">
                    <v-col cols="12" sm="12" md="8">
                      <h2 style="font-weight:600" class="pa-0 ma-0 green--text text-center">
                        {{total_active}}
                      </h2>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col> 
          <v-col cols="12" sm="12" md="6">
            <v-card class="rounded-l red lighten-4" outlined height="198px">
              <v-card-title class="">
                <div class="d-flex">
                  <v-icon color="red" >
                    mdi-chart-bubble
                  </v-icon>
                  <div class="ml-3">
                    Non Active
                  </div>
                </div>
              </v-card-title>
              <v-card-text class="p-3">
                <v-container class="py-auto fill-height">
                  <v-row align="center" justify="center">
                    <v-col cols="12" sm="12" md="8">
                      <h2 style="font-weight:600" class="pa-0 ma-0 red--text text-center">
                        {{ total_non_active }}
                      </h2>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-card class="mt-6 rounded-xxl pb-4 elevation-5" height="auto" color="#fff">
        <v-card-text>
          <v-data-table
            dense
            :headers="headers"
            :items="itemLists"
            class="elevation-1 mb-5"
            :items-per-page="30"
            :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50, -1]
            }"
            :loading="$store.state.overlay"
            height="440"
            fixed-header
            :divider="true"
            :light="true"
            :search="searchItem" 
            :item-class="tr_datatable"
        >    
            <template v-slot:top>
                <v-toolbar flat color="white" class="mt-2 mb-2">
                    <div class="d-flex w-100">
                        <v-text-field solo style="max-width: 300px;" class="border-12 mr-3 " v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                        <v-spacer></v-spacer>
                        <v-btn
                        small
                        color="#fff"
                        class="py-5 mr-3 hidden-sm-and-down"
                        @click="getPullData()"
                        >
                            <v-icon>mdi-cached</v-icon>
                        </v-btn>
                        <v-btn
                        small
                        color="#fff"
                        class="py-5 mr-3"
                        @click="dialogfilter = true"
                        >
                            <v-icon>mdi-filter-variant</v-icon>
                        </v-btn>
                        <v-btn
                        small
                        color="#005c37"
                        class="py-5 mr-3 text-white hidden-sm-and-down"
                        >
                            <v-icon>mdi-file-excel</v-icon>
                            <download-excel
                                class="text"
                                :fetch           = "exportExcel"
                                :fields="headersColumn"
                                :before-generate = "startDownload"
                                :before-finish   = "finishDownload">
                                Export Excel
                            </download-excel>
                        </v-btn>
                        <v-btn
                        small
                        color="success"
                        class="py-5 mr-0"
                        @click="dialogCreateRequest = true"
                        >
                        <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </div>
                </v-toolbar>
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
              {{ new Date(item.created_at).toISOString().substr(0, 10) }}
            </template>
            <template v-slot:[`item.description`]="{ item }">
              <div v-html="item.description.substr(0, 200)"></div>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-switch
                true-value="1"
                false-value="0"
                inset
                dense
                v-model="item.status"
                @change="updateStatus(item)"
                class="mt-1 mb-2 mr-1"
                hide-details="true"
              ></v-switch>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu
                  bottom
                  left
              >
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                          color="grey"
                          class="text-center"
                      >
                          <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                  </template>

                  <v-list dense>
                      <v-list-item @click="detailItemEdit(item)">
                          <v-list-item-icon>
                              <v-icon>mdi-pencil</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                              <v-list-item-title>Edit</v-list-item-title>
                          </v-list-item-content>
                      </v-list-item>
                      <!-- <v-list-item @click="deleteItem(item)">
                          <v-list-item-icon>
                              <v-icon>mdi-delete</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                              <v-list-item-title>Delete</v-list-item-title>
                          </v-list-item-content>
                      </v-list-item> -->
                  </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <v-dialog
          v-model="dialogCreateRequest"
          persistent
          max-width="800px"
          hide-overlay
          transition="dialog-bottom-transition"
      >
        <v-card class="rounded-l">
            <v-card-title class="text-center mb-0 pb-0">
                <v-row>
                    <v-col cols="12">
                        <div class="d-flex">
                            <div class="ml-5">
                              Article Baru 
                            </div>
                            <v-spacer></v-spacer>
                            <v-btn
                                class="mx-2"
                                fab
                                x-small
                                color="#e83e8c;"
                                @click="[dialogCreateRequest = false, showDetail = false, clear()]"
                            >
                                <v-icon>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                        </div>
                    </v-col>
                    <v-divider></v-divider>
                </v-row>
            </v-card-title>
            <v-card-text class="ma-0">
              <v-container fluid class="ma-0">
                <v-row>
                  <v-col class="col-12" sm="6" md="6">
                    <h6 class="blue-lcd mb-1">Title <strong style="color:red;">*</strong><span></span></h6>
                    <v-text-field
                      v-model="title"
                      solo
                      dense
                      clearable
                      class="ma-0 pa-0 border-12"
                      hide-details=true
                    ></v-text-field>
                  </v-col>
                  <v-col class="col-12" sm="6" md="6">
                    <h6 class="blue-lcd mb-1">Author <strong style="color:red;">*</strong><span></span></h6>
                    <v-text-field
                      v-model="author"
                      solo
                      dense
                      clearable
                      class="ma-0 pa-0 border-12"
                      hide-details=true
                    ></v-text-field>
                  </v-col>
                  <v-col class="col-12" sm="6" md="3">
                    <h6 class="blue-lcd mb-1">Foto Article </h6>
                    <v-img :src="cropImg" class="rounded-l" v-if="cropImg"></v-img>
                    <v-img src="@/assets/legalpedia.png" class="rounded-l" v-else></v-img>
                    <v-btn
                        v-if="showDetail == false"
                        block
                        color="accent"
                        elevation="2"
                        outlined
                        class="mt-5"
                        @click="changeAvatar()"
                    >
                        Pilih Foto
                    </v-btn>
                    <p v-if="showDetail == false" class="css-bqy72x mt-5 font-12" style="font-size: 12px;">Besar file: maksimum (2 Megabytes). Ekstensi file yang diperbolehkan: .JPG .JPEG .PNG</p>
                  </v-col>
                  <v-col class="col-12" sm="12" md="9">
                    <h6 class="blue-lcd mb-1">Description <strong style="color:red;">*</strong><span></span></h6>
                    <ckeditor :editor="editor" v-model="remark" :config="editorConfig" tag-name="textarea"></ckeditor>
                    <!-- <v-textarea solo class="ma-0 pa-0 border-12" v-model="remark"
                        hide-details=true counter label="Remark" dense></v-textarea> -->
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions class="text-center" >
                <v-row justify="center" v-if="showDetail == false">
                    <v-col cols="12" xs="12" sm="3" md="3">
                        <v-btn block class="rounded-l mr-2 p-3" color="#fff" @click="[dialogCreateRequest = false, showDetail = false, clear()]">Cancel</v-btn>
                    </v-col>
                    <v-col cols="12" xs="12" sm="3" md="3">
                        <v-btn block class="rounded-l text-white p-3" color="success" @click="submit()">Save</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="dialogEdit"
          persistent
          max-width="800px"
          hide-overlay
          transition="dialog-bottom-transition"
      >
        <v-card class="rounded-l">
            <v-card-title class="text-center mb-0 pb-0">
                <v-row>
                    <v-col cols="12">
                        <div class="d-flex">
                            <div class="ml-5">
                              Article Update 
                            </div>
                            <v-spacer></v-spacer>
                            <v-btn
                                class="mx-2"
                                fab
                                x-small
                                color="#e83e8c;"
                                @click="[dialogEdit = false, clear()]"
                            >
                                <v-icon>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                        </div>
                    </v-col>
                    <v-divider></v-divider>
                </v-row>
            </v-card-title>
            <v-card-text class="ma-0">
              <v-container fluid class="ma-0">
                <v-row>
                  <v-col class="col-12" sm="6" md="6">
                    <h6 class="blue-lcd mb-1">Title <strong style="color:red;">*</strong><span></span></h6>
                    <v-text-field
                      v-model="title"
                      solo
                      dense
                      clearable
                      class="ma-0 pa-0 border-12"
                      hide-details=true
                    ></v-text-field>
                  </v-col>
                  <v-col class="col-12" sm="6" md="6">
                    <h6 class="blue-lcd mb-1">Author <strong style="color:red;">*</strong><span></span></h6>
                    <v-text-field
                      v-model="author"
                      solo
                      dense
                      clearable
                      class="ma-0 pa-0 border-12"
                      hide-details=true
                    ></v-text-field>
                  </v-col>
                  <v-col class="col-12" sm="6" md="3">
                    <h6 class="blue-lcd mb-1">Foto Article </h6>
                    <v-img :src="cropImg" class="rounded-l" v-if="cropImg"></v-img>
                    <v-img src="@/assets/legalpedia.png" class="rounded-l" v-else></v-img>
                    <v-btn
                        block
                        color="accent"
                        elevation="2"
                        outlined
                        class="mt-5"
                        @click="changeAvatar()"
                    >
                        Pilih Foto
                    </v-btn>
                    <p class="css-bqy72x mt-5 font-12" style="font-size: 12px;">Besar file: maksimum (2 Megabytes). Ekstensi file yang diperbolehkan: .JPG .JPEG .PNG</p>
                  </v-col>
                  <v-col class="col-12" sm="12" md="9">
                    <h6 class="blue-lcd mb-1">Description <strong style="color:red;">*</strong><span></span></h6>
                    <ckeditor :editor="editor" v-model="remark" :config="editorConfig" tag-name="textarea"></ckeditor>
                    <!-- <v-textarea solo class="ma-0 pa-0 border-12" v-model="remark"
                        hide-details=true counter label="Remark" dense></v-textarea> -->
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions class="text-center" >
                <v-row justify="center">
                    <v-col cols="12" xs="12" sm="3" md="3">
                        <v-btn block class="rounded-l mr-2 p-3" color="#fff" @click="[dialogEdit = false, clear()]">Cancel</v-btn>
                    </v-col>
                    <v-col cols="12" xs="12" sm="3" md="3">
                        <v-btn block class="rounded-l text-white p-3" color="success" @click="submitEdit()">Save</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialog_avatar"
        max-width="500px"
        hide-overlay
      >
        <v-card class="p-3 rounded-xl">
            <v-card-title class="text-center">
                <v-row align="center" justify="center">
                    <v-col cols="10">
                    <h5 style="font-weight:700; color: #1e4393;">
                    Foto Article
                    </h5>
                    </v-col>
                </v-row>  
            </v-card-title>
            <v-card-text>
                <v-container fluid class="fill-height">
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12">
                            <h6>
                                Foto Article
                            </h6>

                            <div v-if="imgSrc" style="width: 100%; height:auto; border: 1px solid gray; display: inline-block;">
                                <vue-cropper
                                    ref='cropper'
                                    :guides="true"
                                    :view-mode="2"
                                    drag-mode="crop"
                                    :min-container-width="250"
                                    :min-container-height="180"
                                    :background="true"
                                    :rotatable="true"
                                    :src="imgSrc"
                                    :img-style="{ 'width': 'auto', 'height': 'auto' }">
                                </vue-cropper>
                            </div>

                            <div v-else>
                                <input type="file" name="image" id="image" @change="setImage" class="form-control" accept="image/*">
                            </div>
                        </v-col>
                    </v-row> 
                </v-container>
            </v-card-text>
            <v-card-actions class="text-center">
                <v-row justify="center">
                    <v-col cols="12" xs="12" sm="3" md="4">
                        <v-btn block class="rounded-l text-white p-4" color="#003871" @click="saveAvatar()">Simpan</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="dialogfilter"
          persistent
          max-width="600px"
          hide-overlay
          transition="dialog-bottom-transition"
      >
        <v-card class="rounded-l">
          <v-card-title class="text-center mb-0 pb-0">
              <v-row>
                  <v-col cols="12">
                      <div class="d-flex">
                          <div class="ml-5">
                            Filter
                          </div>
                          <v-spacer></v-spacer>
                          <v-btn
                              class="mx-2"
                              fab
                              x-small
                              color="#e83e8c;"
                              @click="[dialogfilter = false]"
                          >
                              <v-icon>
                                  mdi-close
                              </v-icon>
                          </v-btn>
                      </div>
                  </v-col>
                  <v-divider></v-divider>
              </v-row>
          </v-card-title>
          <v-card-text class="ma-0">
            <v-container fluid class="ma-0">
              <v-row>
                <v-col class="col-12" sm="6" md="6">
                  <h6 class="blue-lcd mb-1">Start Date </h6>
                  <v-menu
                      ref="dateStartModal"
                      v-model="dateStartModal"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                      >
                      <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                          solo
                          dense
                          v-model="dateStartModel"
                          label="Start Date"
                          persistent-hint
                          append-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          class="ma-0 pa-0 border-12"
                          hide-details=true
                          clearable
                          ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="dateStartModel"
                          no-title
                          @input="[dateStartModal = false]"
                      ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col class="col-12" sm="6" md="6">
                  <h6 class="blue-lcd mb-1">End Date </h6>
                  <v-menu
                      ref="dateEndModal"
                      v-model="dateEndModal"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                      >
                      <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                          solo
                          dense
                          v-model="dateEndModel"
                          label="Start Date"
                          persistent-hint
                          append-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          class="ma-0 pa-0 border-12"
                          hide-details=true
                          clearable
                          ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="dateEndModel"
                          no-title
                          @input="[dateEndModal = false]"
                      ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="text-center">
            <v-row justify="center">
                <v-col cols="12" xs="12" sm="3" md="3">
                    <v-btn block class="rounded-l mr-2" color="#fff" @click="[dialogfilter = false, clear()]">Cancel</v-btn>
                </v-col>
                <v-col cols="12" xs="12" sm="3" md="3">
                    <v-btn block class="rounded-l text-white" color="#20bf6b" @click="[getPullData(), dialogfilter = false]">Filter</v-btn>
                </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import {article_api} from "@/backend-api/article_api"
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    components:{
      VueCropper
    },
    data: () => ({
        snackbar: {
          color: null,
          icon: null,
          mode: null,
          position: "top",
          text: null,
          timeout: 7500,
          title: null,
          visible: false
        },
        editor: ClassicEditor,
        editorData: '<p>Content of the editor.</p>',
        editorConfig: {
          toolbar: {
            items: [
            'undo', 'redo',
            '|', 'heading',
            '|', 'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
            '|', 'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code',
            '|', 'link', 'blockQuote', 'codeBlock',
            '|', 'alignment',
            '|', 'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent'
            ]
          },
          table: {
            contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
          },
          language: 'en'
        },
        headers: [
            { text: 'CREATED DATE', value: 'created_at', align: 'left', class: 'primary--text blue lighten-5' },
            { text: 'TITLE', value: 'title', align: 'left', class: 'primary--text blue lighten-5' },
            { text: 'AUTHOR', value: 'author', align: 'left', class: 'primary--text blue lighten-5' },
            { text: 'DESCRIPTION', value: 'description', align: 'left', class: 'primary--text blue lighten-5' },      
            { text: 'STATUS', value: 'status', align: 'left', class: 'primary--text blue lighten-5' },
            { text: '', value: 'actions', align: 'left', class: 'primary--text blue lighten-5' },
        ],
        dialogInfo: false,
        headersColumn:{
        'CREATED DATE': 'created_at',
        'TITLE': 'title',
        'AUTHOR': 'author',
        'DESCRIPTION': 'description',
        'STATUS' : 'status'
        },
        itemLists: [],
        searchItem: '',
        dateStartModel: '',
        dateStartModal:false,
        dateEndModel: '',
        dateEndModal:false,
        showDetail: false,
        title: '',
        author: '',
        remark:'',
        dialogCreateRequest: false,
        dialog_avatar: false,
        imgSrc: '',
        cropImg: '',
        avatar: '',
        detail: null,
        dialogEdit: false,
        dialogfilter: false 
    }),
    methods:{
        tr_datatable(item) {
            var rowClass = 'tr_datatable'
            return rowClass;
        },
        clear(){
          this.title = ''
          this.author = ''
          this.remark = ''
          this.imgSrc = ''
          this.cropImg = ''
        },
        async getPullData(){
          this.$store.dispatch('setOverlay', true)
          var respData = await article_api.index(`?start_date=${this.dateStartModel ? this.dateStartModel : ''}&end_date=${this.dateEndModel ? this.dateEndModel : ''}`, null, false, false, false)
          if (respData.status === 200) {
            this.itemLists = respData.data.data ? respData.data.data : []
            this.$store.dispatch('setOverlay', false)
          } else{
            this.$store.dispatch('setOverlay', false)
          }
        },
        async exportExcel(){
          var respData = await article_api.index(`?start_date=${this.dateStartModel ? this.dateStartModel : ''}&end_date=${this.dateEndModel ? this.dateEndModel : ''}`, null, false, false, false)
          if (respData.status === 200) {
            return respData.data.data ? respData.data.data : []
          }
        },
        startDownload(){
            this.$store.dispatch('setOverlay', true)
        },
        finishDownload(){
            this.$store.dispatch('setOverlay', false)
        },
        changeAvatar(){
          this.dialog_avatar = true
        },
        reset() {
          this.imgSrc = ''
          this.cropImg = ''
        },
        setImage(e) {
          this.reset()
          const file = e.target.files[0]
          if (!file.type.includes('image/')) {
            alert('Please select an image file');
            return;
          }
          if (typeof FileReader === 'function') {
            const reader = new FileReader()
            reader.onload = (event) => {
              this.imgSrc = event.target.result
            };
            reader.readAsDataURL(file)
          } else {
            alert('Sorry, FileReader API not supported')
          }
        },
        async saveAvatar(){
          this.cropImg = this.imgSrc ? this.$refs.cropper.getCroppedCanvas().toDataURL() : ''
          this.dialog_avatar = false
        },
        async submit(){
          if (this.title == '' || this.author == '' || this.cropImg == '' || this.remark == '') {
            this.snackbar = {
              color: "info",
              icon: "mdi-alert-circle",
              mode: "multi-line",
              position: "top",
              timeout: 7500,
              title: "Info",
              text: 'Please fill your fileds and submit for save',
              visible: true
            };

            return false
          }

          this.$store.dispatch('setOverlay', true)
          var reqBody = {
            'title': this.title,
            'author': this.author,
            'image': this.cropImg,
            'description': this.remark
          }
          const respData = await article_api.store('', reqBody, false, false, false)
          if (respData.status === 200) {
            this.clear()
            this.getPullData()
            this.dialogCreateRequest = false
            this.snackbar = {
              color: "success",
              icon: "mdi-alert-circle",
              mode: "multi-line",
              position: "top",
              timeout: 7500,
              title: "Success",
              text: 'Success tambah article',
              visible: true
            };
          }
        },
        async updateStatus(item){
          var reqBody = {
            'id': item.id,
            'status': item.status
          }
          const respData = await article_api.update_status('', reqBody, false, false, false)
          if (respData.status === 200) {
            this.getPullData()
          }
        },
        detailItemEdit(item){
          this.detail = item
          this.title = item.title
          this.author = item.author
          this.cropImg = item.image
          this.remark = item.description
          this.dialogEdit = true
        },
        async submitEdit(){
          if (this.title == '' || this.author == '' || this.cropImg == '' || this.remark == '') {
            this.snackbar = {
              color: "info",
              icon: "mdi-alert-circle",
              mode: "multi-line",
              position: "top",
              timeout: 7500,
              title: "Info",
              text: 'Please fill your fileds and submit for save',
              visible: true
            };

            return false
          }

          this.$store.dispatch('setOverlay', true)
          var reqBody = {
            'id' : this.detail.id,
            'title': this.title,
            'author': this.author,
            'image': this.cropImg,
            'description': this.remark
          }
          const respData = await article_api.update('', reqBody, false, false, false)
          if (respData.status === 200) {
            this.clear()
            this.getPullData()
            this.dialogEdit = false
            this.snackbar = {
              color: "success",
              icon: "mdi-alert-circle",
              mode: "multi-line",
              position: "top",
              timeout: 7500,
              title: "Success",
              text: 'Success memperbarui article',
              visible: true
            };
          }
        },
    },
    async mounted() { 
        this.$store.dispatch('setOverlay', true)
        await this.getPullData()
        this.$store.dispatch('setOverlay', false)
    },
    watch: {
    },
    computed:{
      total_active(){
          var total_active = 0
          for (let index = 0; index < this.itemLists.length; index++) {
              if (this.itemLists[index].status == 1) {
                  total_active = total_active + 1;
              }
          }
          
          return (total_active | 0)
      },
      total_non_active(){
          var total_non_active = 0
          for (let index = 0; index < this.itemLists.length; index++) {
              if (this.itemLists[index].status == 0) {
                  total_non_active = total_non_active + 1;
              }
          }
          
          return (total_non_active | 0)
      },
    }
}
</script>

<style scoped>
.tr_datatable{
background-color: #F5F7F8 !important;
}

</style>